import React, { Component } from "react"
import { globalHistory } from "@reach/router"
import axios from "axios"
import * as Config from "../../utils/Auth/config"
import { userLogin } from "../../utils/Auth"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { navigate } from "gatsby"
// @material-ui/icons
import Group from "@material-ui/icons/Group"
import Check from "@material-ui/icons/Check"
// core components
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import Button from "../CustomButtons/Button.jsx"
import Card from "../Card/Card.jsx"
import CustomInput from "../CustomInput/CustomInput.jsx"
import InfoArea from "../InfoArea/InfoArea.jsx"
// styles
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import CardHeader from "../Card/CardHeader"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
}

function hasWhiteSpace(s) {
  return /\s/g.test(s)
}

class Register extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      password2: "",
      success: "",
      error: "",
      redirect: "/u/dashboard",
      agree: false,
      subscribe: true,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const key = event.target.name
    const value = event.target.value

    this.setState({
      [key]: value,
    })
  }

  loginCallback = () => {
    setTimeout(() => {
      navigate("/u/dashboard")
      // navigate(
      //   globalHistory.location.state.redirect !== undefined || false
      //     ? globalHistory.location.state.redirect
      //     : "/u/dashboard"
      // )
    }, 1500)
    this.setState({})
  }

  handleSubmit(event) {
    event.preventDefault()
    let self = this
    const {
      name,
      firstName,
      lastName,
      email,
      phone,
      password,
      password2,
      agree,
    } = self.state

    if (hasWhiteSpace(name)) {
      self.setState({
        success: "",
        error: "Username cannot contain spaces",
      })
      return
    }

    if (password !== password2) {
      self.setState({
        success: "",
        error: "Passwords do not match",
      })
      return
    }

    if (!agree) {
      self.setState({
        success: "",
        error: "You must agree to the Terms of Use before registration",
      })
      return
    }

    axios
      .post(Config.endpoints["registration"] + "?_format=json", {
        name: [{ value: name.trim() }],
        field_first_name: [{ value: firstName.trim() }],
        field_last_name: [{ value: lastName.trim() }],
        field_phone: [{ value: phone.trim() }],
        mail: [{ value: email.trim() }],
        pass: [{ value: password }],
      })
      .then(function (response) {
        // console.log(response)
        self.state.subscribe && self.handleSubscribe(email)
        userLogin(name, password, self.loginCallback)
        self._isMounted &&
          self.setState({
            success: "Registration successful... Logging you in now :)",
            error: "",
          })
      })
      .catch(function (error) {
        console.log(error.response)
        let errorResponse = error.response.data.message
        errorResponse = errorResponse.replace(/(?:\r\n|\r|\n)/g, "<br />")
        errorResponse = errorResponse.replace(
          "Unprocessable Entity: validation failed.",
          ""
        )
        errorResponse = errorResponse.replace("name: ", "")
        errorResponse = errorResponse.replace("mail: ", "")
        self.setState({
          success: "",
          error: errorResponse,
        })
      })
  }

  handleToggle() {
    const { agree } = this.state
    this._isMounted && this.setState({ agree: !agree })
  }

  handleSubscribeToggle() {
    const { subscribe } = this.state
    this._isMounted && this.setState({ subscribe: !subscribe })
  }

  handleSubscribe = (email) => {
    fetch("/.netlify/functions/mailchimp", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    }).then(() => {
      console.log("subscribed")
    })
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props
    const {
      name,
      firstName,
      lastName,
      email,
      phone,
      password,
      password2,
      success,
      error,
      subscribe,
      agree,
    } = this.state
    return (
      <GridContainer
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ padding: "1.5rem 16px" }}
      >
        <Card style={style.cardBorder}>
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h5 className={classes.cardTitleWhite}>Register</h5>
          </CardHeader>
          <div className={classes.textCenter} style={{ margin: "20px" }}>
            <Link to="/login" replace>
              Already have an account?
            </Link>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <InfoArea
                className={classes.infoArea}
                title="CCO Community"
                description={
                  <p>
                    AT CCO, we live and breathe Center Console boats. This page
                    isn't for everyone, however, if you love CC's, you came to
                    the right place!
                    <br />
                    <br />
                    Register now and join one of the most committed center
                    console communities on the internet.
                    <br />
                    <br />
                    We also offer more than just listing services to connect
                    buyers and sellers within the boating and fishing market.
                  </p>
                }
                icon={Group}
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <form onSubmit={this.handleSubmit} className={classes.form}>
                <CustomInput
                  labelText={"Username"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    value: name,
                    onChange: this.handleChange,
                    type: "text",
                    className: "form-control",
                    name: "name",
                    placeholder: "Username...",
                    autoFocus: false,
                  }}
                />
                <CustomInput
                  labelText={"First Name"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    value: firstName,
                    onChange: this.handleChange,
                    type: "text",
                    className: "form-control",
                    name: "firstName",
                    placeholder: "First Name...",
                    autoFocus: false,
                  }}
                />
                <CustomInput
                  labelText={"Last Name"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    value: lastName,
                    onChange: this.handleChange,
                    type: "text",
                    className: "form-control",
                    name: "lastName",
                    placeholder: "Last Name...",
                    autoFocus: false,
                  }}
                />
                <CustomInput
                  labelText={"Email Address"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    name: "email",
                    value: email,
                    onChange: this.handleChange,
                    type: "email",
                    className: "form-control",
                    placeholder: "Email...",
                    autoFocus: false,
                  }}
                />
                <CustomInput
                  labelText={"Phone Number"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: false,
                  }}
                  inputProps={{
                    name: "phone",
                    value: phone,
                    onChange: this.handleChange,
                    type: "tel",
                    className: "form-control",
                    placeholder: "Phone Number...",
                    autoFocus: false,
                  }}
                />
                <CustomInput
                  labelText={"Password"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    name: "password",
                    value: password,
                    onChange: this.handleChange,
                    type: "password",
                    className: "form-control",
                    placeholder: "Password...",
                    autoFocus: false,
                  }}
                />
                <CustomInput
                  labelText={"Confirm Password"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    name: "password2",
                    value: password2,
                    onChange: this.handleChange,
                    type: "password",
                    className: "form-control",
                    placeholder: "Confirm Password...",
                    autoFocus: false,
                  }}
                />
                <div className="form-group messages">
                  <p className="success">{success}</p>
                  {error && (
                    <p
                      className="error"
                      dangerouslySetInnerHTML={{ __html: error }}
                    />
                  )}
                </div>
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  checked={subscribe}
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.handleSubscribeToggle(1)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={<span>Subscribe to CCO Newsletter</span>}
                />
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  checked={agree}
                  control={
                    <Checkbox
                      tabIndex={-2}
                      onClick={() => this.handleToggle(1)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                      required={true}
                    />
                  }
                  label={
                    <span>
                      I have read and agree to the{" "}
                      <a href={"/terms-of-use"} target="_blank">
                        Terms of Use
                      </a>
                      .
                    </span>
                  }
                />
                <div className={classes.textCenter}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{ padding: "10px 50px 20px", marginTop: "30px" }}
                  >
                    Create My Account!
                  </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </Card>
      </GridContainer>
    )
  }
}

export default withStyles(javascriptStyles)(Register)
