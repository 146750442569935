import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Register from "../components/Register"
import { isAuthenticated } from "../utils/Auth"
import { navigate } from "gatsby"

const RegisterPage = () => {
  isAuthenticated() && navigate("/u/dashboard")

  return (
    <Layout>
      <SEO title="CCO User Registration" />
      <Register />
    </Layout>
  )
}
export default RegisterPage
